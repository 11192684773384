// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import CompanyList from './components/CompanyList';
import CompanyDetail from './components/CompanyDetail';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import SearchResults from './components/SearchResults';


function App() {
    const isAuthenticated = !!localStorage.getItem('token');
    const userRole = localStorage.getItem('role');

    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />
          {isAuthenticated ? (
            <>
              <Route path="/" element={<CompanyList />} />
              <Route path="/companies/:id" element={<CompanyDetail />} />
              <Route path="/search" element={<SearchResults />} />
              {userRole === 'admin' && (
                <Route path="/admin" element={<AdminPanel />} />
              )}
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
        <Footer />
      </Router>
    );
}

export default App;
