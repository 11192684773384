// components/SearchResults.js
import React, { useState, useEffect } from 'react';
import API from '../services/api';
import { Link, useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchResults() {
  const [results, setResults] = useState([]);
  const query = useQuery().get('query');

  useEffect(() => {
    if (!query) {
      return;
    }
    API.get(`/companies/search?query=${encodeURIComponent(query)}`)
      .then((res) => setResults(res.data))
      .catch((err) => console.error(err));
  }, [query]);

  return (
    <div>
      <h2>Suchergebnisse für "{query}"</h2>
      {results.length > 0 ? (
        <ul>
          {results.map((company) => (
            <li key={company._id}>
              <Link to={`/companies/${company._id}`}>{company.name}</Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>Keine Ergebnisse gefunden.</p>
      )}
    </div>
  );
}

export default SearchResults;
