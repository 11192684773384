// components/CompanyDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../services/api';

function CompanyDetail() {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (id === 'new') {
      setCompany({
        name: '',
        contactMade: false,
        technologies: [],
        contactPersons: [],
        comments: '',
        address: '',
        website: '',
        lastContactDate: '',
        status: 'Interessiert',
        industry: '',
        jobOfferLink: '',
      });
      setIsEditing(true);
    } else {
      API.get(`/companies/${id}`)
        .then((res) => setCompany(res.data))
        .catch((err) => console.error(err));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCompany((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleTechnologyChange = (e) => {
    setCompany((prevState) => ({
      ...prevState,
      technologies: e.target.value.split(',').map((tech) => tech.trim()),
    }));
  };

  const handleContactPersonChange = (index, e) => {
    const { name, value } = e.target;
    const updatedContactPersons = [...company.contactPersons];
    updatedContactPersons[index][name] = value;
    setCompany((prevState) => ({
      ...prevState,
      contactPersons: updatedContactPersons,
    }));
  };

  const addContactPerson = () => {
    setCompany((prevState) => ({
      ...prevState,
      contactPersons: [
        ...prevState.contactPersons,
        { name: '', position: '', email: '', phone: '' },
      ],
    }));
  };

  const removeContactPerson = (index) => {
    const updatedContactPersons = [...company.contactPersons];
    updatedContactPersons.splice(index, 1);
    setCompany((prevState) => ({
      ...prevState,
      contactPersons: updatedContactPersons,
    }));
  };

  const handleSave = () => {
    if (!company.name) {
      alert('Der Firmenname ist erforderlich.');
      return;
    }

    if (id === 'new') {
      API.post('/companies', company)
        .then((res) => {
          setCompany(res.data);
          setIsEditing(false);
        })
        .catch((err) => console.error(err));
    } else {
      API.put(`/companies/${id}`, company)
        .then((res) => {
          setCompany(res.data);
          setIsEditing(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleDelete = () => {
    if (window.confirm('Sind Sie sicher, dass Sie diese Firma löschen möchten?')) {
      API.delete(`/companies/${id}`)
        .then(() => {
          window.location.href = '/';
        })
        .catch((err) => console.error(err));
    }
  };

  if (!company) return <div>Laden...</div>;

  return (
    <div className="company-detail">
      {isEditing ? (
        <>
          <h2>{id === 'new' ? 'Neue Firma erstellen' : 'Firma bearbeiten'}</h2>
          <form>
            <label>
              Firmenname*:
              <input
                type="text"
                name="name"
                value={company.name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Kontakt aufgenommen:
              <input
                type="checkbox"
                name="contactMade"
                checked={company.contactMade}
                onChange={handleChange}
              />
            </label>
            <label>
              Technologien (durch Komma getrennt):
              <input
                type="text"
                name="technologies"
                value={company.technologies.join(', ')}
                onChange={handleTechnologyChange}
              />
            </label>
            <label>
                <span>Kontaktpersonen:</span>
                {company.contactPersons.map((person, index) => (
                    <div key={index} className="contact-person-form">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={person.name}
                        onChange={(e) => handleContactPersonChange(index, e)}
                    />
                    <input
                        type="text"
                        name="position"
                        placeholder="Position"
                        value={person.position}
                        onChange={(e) => handleContactPersonChange(index, e)}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="E-Mail"
                        value={person.email}
                        onChange={(e) => handleContactPersonChange(index, e)}
                    />
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Telefonnummer"
                        value={person.phone}
                        onChange={(e) => handleContactPersonChange(index, e)}
                    />
                    <button type="button" onClick={() => removeContactPerson(index)}>
                        Entfernen
                    </button>
                    </div>
                ))}
                <button type="button" onClick={addContactPerson}>
                    Kontaktperson hinzufügen
                </button>
            </label>
            <label>
              Kommentare:
              <textarea
                name="comments"
                value={company.comments}
                onChange={handleChange}
              ></textarea>
            </label>
            <label>
              Adresse/Standort:
              <input
                type="text"
                name="address"
                value={company.address}
                onChange={handleChange}
              />
            </label>
            <label>
              Webseite:
              <input
                type="url"
                name="website"
                value={company.website}
                onChange={handleChange}
              />
            </label>
            <label>
              Datum des letzten Kontakts:
              <input
                type="date"
                name="lastContactDate"
                value={company.lastContactDate ? company.lastContactDate.substr(0, 10) : ''}
                onChange={handleChange}
              />
            </label>
            <label>
              <span>Status der Beziehung:</span>
              <select name="status" value={company.status} onChange={handleChange}>
                <option value="">Bitte wählen</option>
                <option value="Noch keinen Kontakt">Noch keinen Kontakt</option>
                <option value="Interessiert">Interessiert</option>
                <option value="Kein Interesse">Kein Interesse</option>
                <option value="Folgeanruf erforderlich">Folgeanruf erforderlich</option>
              </select>
            </label>
            <label>
              Branche:
              <input
                type="text"
                name="industry"
                value={company.industry}
                onChange={handleChange}
              />
            </label>
            <label>
              Link zum Stellenangebot:
              <input
                type="url"
                name="jobOfferLink"
                value={company.jobOfferLink}
                onChange={handleChange}
              />
            </label>
            <button type="button" onClick={handleSave}>
              Speichern
            </button>
            <button type="button" onClick={() => setIsEditing(false)}>
              Abbrechen
            </button>
          </form>
        </>
      ) : (
        <>
          <h2>{company.name}</h2>
          <div className="company-card">
            <div className="company-section">
              <h3>Allgemeine Informationen</h3>
              <p>
                <strong>Kontakt aufgenommen:</strong> {company.contactMade ? 'Ja' : 'Nein'}
              </p>
              <p>
                <strong>Technologien:</strong> {company.technologies.join(', ')}
              </p>
              <p>
                <strong>Branche:</strong> {company.industry}
              </p>
              <p>
                <strong>Status der Beziehung:</strong> {company.status}
              </p>
            </div>
  
            <div className="company-section">
              <h3>Kontaktpersonen</h3>
              {company.contactPersons.map((person, index) => (
                <div key={index} className="contact-person-card">
                  <p>
                    <strong>Name:</strong> {person.name}
                  </p>
                  <p>
                    <strong>Position:</strong> {person.position}
                  </p>
                  <p>
                    <strong>E-Mail:</strong>{' '}
                    <a href={`mailto:${person.email}`}>{person.email}</a>
                  </p>
                  <p>
                    <strong>Telefonnummer:</strong>{' '}
                    <a href={`tel:${person.phone}`}>{person.phone}</a>
                  </p>
                </div>
              ))}
            </div>
  
            <div className="company-section">
            < h3>Weitere Informationen</h3>
              <p>
                <strong>Adresse/Standort:</strong> {company.address}
              </p>
              {company.website && (
                <button
                  onClick={() => window.open(company.website, '_blank')}
                  className="link-button"
                >
                  Zur Webseite
                </button>
              )}
              {company.jobOfferLink && (
                <button
                  onClick={() => window.open(company.jobOfferLink, '_blank')}
                  className="link-button"
                >
                  Zum Stellenangebot
                </button>
              )}
              <p>
                <strong>Datum des letzten Kontakts:</strong>{' '}
                {company.lastContactDate
                  ? new Date(company.lastContactDate).toLocaleDateString()
                  : ''}
              </p>
            </div>
  
            <div className="company-section">
              <h3>Kommentare</h3>
              <p>{company.comments}</p>
            </div>
          </div>
  
          <div className="button-group">
            <button onClick={() => setIsEditing(true)}>Bearbeiten</button>
            <button onClick={handleDelete}>Löschen</button>
          </div>
        </>
      )}
    </div>
  );
}

export default CompanyDetail;
