// components/CompanyList.js
import React, { useState, useEffect } from 'react';
import API from '../services/api';
import { Link } from 'react-router-dom';

function CompanyList() {
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        API.get('/companies')
            .then(res => setCompanies(res.data))
            .catch(err => console.error(err));
    }, []);

    return (
        <div className="company-list">
          <h2>Firmenliste</h2>
          <Link to="/companies/new" className="add-company-button">
            Neue Firma hinzufügen
          </Link>
          <ul>
            {companies.map((company) => (
              <li key={company._id}>
                <Link to={`/companies/${company._id}`}>{company.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      );
}

export default CompanyList;
