// components/SearchBar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchBar() {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim() === '') {
        alert('Bitte geben Sie einen Suchbegriff ein.');
        return;
    }
    navigate(`/search?query=${encodeURIComponent(query)}`);
  };

  return (
    <form onSubmit={handleSubmit} className="search-bar">
      <input
        type="text"
        placeholder="Nach Firmen suchen..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button type="submit">Suchen</button>
    </form>
  );
}

export default SearchBar;
