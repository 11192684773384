// components/Login.js
import React, { useState } from 'react';
import API from '../services/api';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        API.post('/auth/login', { username, password })
            .then((res) => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('role', res.data.role);
                window.location.href = '/';
            })
            .catch((err) => {
                console.error(err);
                setMessage('Fehler beim Login.');
            });
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Benutzername"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Passwort"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Anmelden</button>
            </form>
        </div>
    );
}

export default Login;
