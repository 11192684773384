// components/UserManagement.js
import React, { useState, useEffect } from 'react';
import API from '../services/api';

function UserManagement() {
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        role: 'user',
    });

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        API.get('/users')
            .then((res) => setUsers(res.data))
            .catch((err) => console.error(err));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddUser = () => {
        API.post('/users', formData)
            .then((res) => {
                setUsers([...users, res.data]);
                setFormData({ username: '', password: '', role: 'user' });
            })
            .catch((err) => console.error(err));
    };

    const handleEditUser = (user) => {
        setEditingUser(user);
        setFormData({ username: user.username, password: '', role: user.role });
    };

    const handleUpdateUser = () => {
        API.put(`/users/${editingUser._id}`, formData)
            .then((res) => {
                fetchUsers();
                setEditingUser(null);
                setFormData({ username: '', password: '', role: 'user' });
            })
            .catch((err) => console.error(err));
    };

    const handleCancelEdit = () => {
        setEditingUser(null);
        setFormData({ username: '', password: '', role: 'user' });
    };

    const handleDeleteUser = (id) => {
        if (window.confirm('Benutzer wirklich löschen?')) {
            API.delete(`/users/${id}`)
                .then(() => {
                    setUsers(users.filter((user) => user._id !== id));
                })
                .catch((err) => console.error(err));
        }
    };

    return (
        <div>
            <h3>Benutzerverwaltung</h3>

            {/* Formular zum Hinzufügen oder Bearbeiten von Benutzern */}
            <div>
                <h4>{editingUser ? 'Benutzer bearbeiten' : 'Neuen Benutzer hinzufügen'}</h4>
                <input
                    type="text"
                    name="username"
                    placeholder="Benutzername"
                    value={formData.username}
                    onChange={handleChange}
                    required
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Passwort"
                    value={formData.password}
                    onChange={handleChange}
                    required={!editingUser} // Passwort ist beim Bearbeiten optional
                />
                <select name="role" value={formData.role} onChange={handleChange}>
                    <option value="user">Benutzer</option>
                    <option value="admin">Admin</option>
                </select>
                {editingUser ? (
                    <>
                        <button onClick={handleUpdateUser}>Aktualisieren</button>
                        <button onClick={handleCancelEdit}>Abbrechen</button>
                    </>
                ) : (
                    <button onClick={handleAddUser}>Benutzer hinzufügen</button>
                )}
            </div>

            {/* Benutzerliste */}
            <h4>Bestehende Benutzer</h4>
            <table>
                <thead>
                    <tr>
                        <th>Benutzername</th>
                        <th>Rolle</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user._id}>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                            <td>
                                <button onClick={() => handleEditUser(user)}>Bearbeiten</button>
                                <button onClick={() => handleDeleteUser(user._id)}>Löschen</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default UserManagement;
