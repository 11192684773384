// components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';


function Header() {
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('role');

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.href = '/login';
    };

    return (
        <header>
          <h1>Potenzielle Kunden Basel</h1>
          <nav>
            {token ? (
              <>
                <Link to="/">Firmen</Link>
                {userRole === 'admin' && <Link to="/admin">Adminbereich</Link>}
                <button onClick={handleLogout}>Logout</button>
              </>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </nav>
          {token && <SearchBar />}
        </header>
      );
}

export default Header;
